import Cookies from "js-cookie";
import XClass from "data-xclass";

//=================
//  CSS Reset
//=================
import "normalize.css/normalize.css";
import "css/utilities/reset.pcss";
import "css/utilities/list-unstyled.pcss";

//=================
//  Fonts
//=================
import "fonts/Roboto/font.css";
import "fonts/RobotoBold/font.css";
import "fonts/RobotoMono/font.css";

//=================
//  Components
//=================

import "components/accordion/accordion.js";
import "components/editor/editor.js";
import "components/iframe/iframe.js";

import "js/components/forms/csrf.js";
import "js/components/forms/reCaptcha.js";
import "js/components/forms/utm.js";
import "js/components/in-view.js";

import "css/components/forms/errors.pcss";

// Swiper
import "swiper/css/bundle";
import "components/swiper/swiper.pcss";

//=================
//  BEM
//=================

// Tools
import "bem/common.blocks/container/container.js";
import "bem/common.blocks/image-card/image-card.js";
import "bem/common.blocks/infinite-scroll/infinite-scroll.js";
import "bem/common.blocks/lazy-box/lazy-box.js";

// Atomic blocks
import "bem/common.blocks/article-footer/article-footer.js";
import "bem/common.blocks/article-list/article-list.js";
import "bem/common.blocks/breadcrumbs/breadcrumbs.js";
import "bem/common.blocks/button/button.js";
import "bem/common.blocks/checkbox/checkbox.js";
import "bem/common.blocks/developer/developer.js";
import "bem/common.blocks/error-list/error-list.js";
import "bem/common.blocks/footer-menu/footer-menu.js";
import "bem/common.blocks/grecaptcha-hint/grecaptcha-hint.js";
import "bem/common.blocks/hamburger/hamburger.js";
import "bem/common.blocks/header-phone/header-phone.js";
import "bem/common.blocks/heading/heading.js";
import "bem/common.blocks/main-menu/main-menu.js";
import "bem/common.blocks/mobile-menu/mobile-menu.js";
import "bem/common.blocks/page/page.js";
import "bem/common.blocks/preloader/preloader.js";
import "bem/common.blocks/radiobox/radiobox.js";
import "bem/common.blocks/share/share.js";
import "bem/common.blocks/social-media/social-media.js";

// Form fields
import "bem/common.fields/checkbox-form-field/checkbox-form-field.js";
import "bem/common.fields/checkbox-option/checkbox-option.js";
import "bem/common.fields/checkbox-select-form-field/checkbox-select-form-field.js";
import "bem/common.fields/radiobox-option/radiobox-option.js";
import "bem/common.fields/radiobox-select-form-field/radiobox-select-form-field.js";
import "bem/common.fields/recaptcha-form-field/recaptcha-form-field.js";
import "bem/common.fields/select-form-field/select-form-field.js";
import "bem/common.fields/text-form-field/text-form-field.js";
import "bem/common.fields/textarea-form-field/textarea-form-field.js";

// Composite blocks
import "bem/common.blocks/article-topic/article-topic.js";
import "bem/common.blocks/blog-block/blog-block.js";
import "bem/common.blocks/contact-block/contact-block.js";
import "bem/common.blocks/footer/footer.js";
import "bem/common.blocks/hero/hero.js";
import "bem/common.blocks/header/header.js";
import "bem/common.blocks/map-block/map-block.js";
import "bem/common.blocks/share-block/share-block.js";
import "bem/common.blocks/side-panel/side-panel.js";

// Forms
import "bem/common.forms/contact-form/contact-form.js";

//=================
//  CSS utilities
//=================

import "css/utilities/overflow-hidden.pcss";
import "css/utilities/spaces.pcss";
import "css/utilities/visually-hidden.pcss";

//=================
//  Application
//=================

// Fonts
import "./fonts.js";

// Styles
import "css/app.pcss";

// Pages
import "bem/common.pages/blog-article-page/blog-article-page.js";
import "bem/common.pages/error-page/error-page.js";

window.addEventListener("DOMContentLoaded", () => {
    XClass.start();
});

// Включение плавного скролла страницы, но так, чтобы он
// не срабатывал при загрузке страницы.
window.addEventListener("load", () => {
    document.documentElement.style.scrollBehavior = "smooth";
})

export default {
    Cookies,
    XClass
};
