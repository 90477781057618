import XClass from "data-xclass";
import { AjaxFormBase } from "js/abstracts/forms/ajaxFormBase.js";

import "./contact-form.pcss";

export class ContactForm extends AjaxFormBase {
    _buildFormData() {
        const data = super._buildFormData();
        data.append("referer", location.href);
        return data;
    }

    async onBeforeSend() {
        this.getFields().forEach(field => {
            field.disabled = true;
        });

        const submitButton = this.formElement.querySelector('[type="submit"]:not([hidden])');
        if (submitButton) {
            submitButton.disabled = true;
        }
    }

    async onFinalize() {
        await super.onFinalize();

        this.getFields().forEach(field => {
            field.disabled = false;
        });

        const submitButton = this.formElement.querySelector('[type="submit"]:not([hidden])');
        if (submitButton) {
            submitButton.disabled = false;
        }
    }

    async onSuccess(data) {
        // TODO: success popup
        this.formElement.reset();
    }
}

XClass.register("contact-form", {
    dependencies: ["utm-form", "csrf-form", "recaptcha-v2-form"],
    init: function (element) {
        element._contactForm = new ContactForm(element);
    },
    destroy: function (element) {
        if (element._contactForm) {
            element._contactForm.destroy();
            delete element._contactForm;
        }
    }
});
